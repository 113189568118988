<template>
  <div>
    <b-row>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.complex')"
            :placeholder="$t('placeholder.select_complex')"
            :value="complexes.find(el => el.id === form.complex_id)"
            :options="complexes"
            required
            @select="form.complex_id = $event.id"
        />
      </b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.block')"
            :placeholder="$t('placeholder.select_block')"
            :value="blocks.find(el => el.id === form.block_id)"
            :options="blocks"
            required
            :disabled="!form.complex_id"
            @select="form.block_id = $event.id"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <app-input
            :label="$t('label.model')"
            v-model="form.model"
            :placeholder="$t('placeholder.model')"
            required
        />
      </b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.number')"
            v-model="form.number"
            :placeholder="$t('placeholder.number')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <app-input
            :label="$t('label.factory_number')"
            v-model="form.factory_number"
            :placeholder="$t('placeholder.factory_number')"
            required
        />
      </b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.serial_number')"
            v-model="form.serial_number"
            :placeholder="$t('placeholder.serial_number')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <app-multiselect
          :label="$t('label.manufacture_date')"
          :placeholder="$t('placeholder.manufacture_date')"
          :value="yearsList.find(el => el.value === parseInt(form.manufacture_date))"
          :options="yearsList"
          required
          @select="form.manufacture_date = String($event.id)"
        />
      </b-col>
      <b-col md="6">
        <app-multiselect
          :label="$t('label.start_date')"
          :placeholder="$t('placeholder.start_date')"
          :value="yearsList.find(el => el.value === parseInt(form.start_date))"
          :options="yearsList"
          required
          @select="form.start_date = String($event.id)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <app-input
            :label="$t('label.stops_number')"
            v-model="form.stops_number"
            :placeholder="$t('placeholder.stops_number')"
            required
        />
      </b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.load_capacity')"
            v-model="form.load_capacity"
            :placeholder="$t('placeholder.load_capacity')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <app-input
            :label="$t('label.speed')"
            v-model="form.speed"
            :placeholder="$t('placeholder.speed')"
            required
        />
      </b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.state_registration')"
            v-model="form.state_registration"
            :placeholder="$t('placeholder.state_registration')"
            required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppDatepicker,
    AppInput,
    AppMultiselect
  },
  props: {
    blockId: {
      default: null
    },
    elevator: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      updatePageFlag: false,
      form: {
        complex_id: null,
        block_id: null,
        model: null,
        number: null,
        factory_number: null,
        serial_number: null,
        manufacture_date: null,
        start_date: null,
        stops_number: null,
        load_capacity: null,
        speed: null,
        state_registration: null,
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('complexesStore/GET_LIST');
    this.$store.dispatch('blocksStore/GET_BLOCK', this.blockId);
  },
  computed: {
    ...mapGetters({
      complexes: 'complexesStore/COMPLEXES',
      blocks: 'blocksStore/BLOCKS',
      block: 'blocksStore/BLOCK'
    }),
    computedFormComplexId() {
      return this.form.complex_id;
    },
    yearsList() {
      const min = 2010;
      const max = new Date().getFullYear();
      let res = [];

      for(let i = min; i <= max; i++) {
        res.push({id: i, label: i, value: i});
      }

      return res;
    }
  },
  watch: {
    block(data) {
      if (data) {
        this.form.complex_id = data.complex.id;
      }
    },
    blocks() {
      this.form.block_id = this.blockId;
    },
    computedFormComplexId(id) {
      this.$store.dispatch('blocksStore/GET_BLOCKS', {complex_id: id})
    },
    elevator(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.complex_id = data.complex.id;
        this.form.block_id = data.block_id;

        this.form.model = data.model;
        this.form.number = data.number;
        this.form.factory_number = data.factory_number;
        this.form.serial_number = data.serial_number;
        this.form.manufacture_date = data.manufacture_date;
        this.form.start_date = data.start_date;
        this.form.stops_number = data.stops_number;
        this.form.load_capacity = data.load_capacity;
        this.form.speed = data.speed;
        this.form.state_registration = data.state_registration;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>